<template>
  <div
    v-on-click-outside="() => $emit('closeModal')"
    data-cy="contacts-modal"
    class="bg-grey-hover z-1"
    :class="[
      !ignorePlacement && {
        'top-7 left-28': position === 'bottom',
        'bottom-12 left-0': position === 'top'
      },
      { absolute: !ignorePlacement }
    ]"
  >
    <contacts-list
      v-if="contacts"
      :id="value.id"
      :contacts="contacts"
      class="w-90 min-h-40 max-h-70 overflow-y-auto p-2 bg-grey-hover"
    />
  </div>
</template>
<script lang="ts" setup>
import { onMounted } from "vue";
import { useContacts } from "@/hooks/contacts";
import ContactsList from "@/views/deals/components/contacts/ContactsList.vue";
import type { PropType } from "vue";
import type {
  ContactProfile,
  ApplicationWithContactData
} from "@/models/applications";
import type { ContactTelephones } from "@/models/communicationLogs";
import { getContactsWithCalleeType } from "@/helpers/communication";

const props = defineProps({
  value: {
    type: Object as PropType<ApplicationWithContactData>,
    required: true
  },
  position: {
    type: String as PropType<"top" | "bottom">,
    default: "bottom"
  },
  ignorePlacement: {
    type: Boolean,
    default: false
  }
});

const { contacts, setContacts } = useContacts();

onMounted(() => {
  if (props.value?.personal_information) {
    const contactsInfo = getContactsWithCalleeType({
      business: props.value.business,
      owners: [
        props.value.personal_information,
        ...(props.value.business?.other_owners || [])
      ]
    });
    setContacts(props.value.id, contactsInfo);
    return;
  }
  const contactsInfo = [
    {
      business_legal_name: props.value.name,
      telephones:
        props.value?.phone_numbers?.map((business) => business.phone_number) ||
        []
    },
    ...(props.value?.relationships?.reduce((acc: ContactProfile[], entity) => {
      if (entity.type === "owner") {
        acc.push(entity.profile);
      }
      return acc;
    }, []) || [])
  ] as ContactTelephones[];
  setContacts(props.value.id, contactsInfo);
});
</script>
